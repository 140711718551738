var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm fm", attrs: { title: "상세" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-3" },
              [
                _c("c-label-text", {
                  attrs: { title: "작성부서/이름/일", value: _vm.request },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-1" },
              [
                _c("c-label-text", {
                  attrs: { title: "사업장", value: _vm.assessPlan.plantName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "평가기간",
                    value:
                      _vm.assessPlan.assessmentStartDate +
                      "~" +
                      _vm.assessPlan.assessmentEndDate,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-1" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "평가년도",
                    value: _vm.assessPlan.assessmentYear,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-1" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "정기/수시",
                    value: _vm.assessPlan.ramAssessTypeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-1" },
              [
                _c("c-label-text", {
                  attrs: { title: "Matrix", value: _vm.assessPlan.matrixName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "평가명",
                    value: _vm.assessPlan.assessmentName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-9 col-lg-9" },
              [
                _c("c-label-text", {
                  attrs: { title: "상세내용", value: _vm.assessPlan.remark },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 fmInfo",
              },
              [
                _c("c-upload", {
                  attrs: {
                    editable: false,
                    attachInfo: {
                      isSubmit: true,
                      taskClassCd: "RISK_PLAN",
                      taskKey: _vm.assessPlan.ramRiskAssessmentPlanId,
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "q-mt-sm",
          attrs: { title: "평가팀", noMarginPadding: true },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12 q-mt-xs" },
              _vm._l(_vm.assessPlan.teams, function (item, idx) {
                return _c("q-chip", {
                  key: idx,
                  attrs: {
                    outline: "",
                    square: "",
                    color: "primary",
                    "text-color": "white",
                    icon: "person_outline",
                    label: _vm.setTeamLabel(item),
                    title: _vm.setTeamLabel(item),
                  },
                  on: {
                    click: function ($event) {
                      _vm.truncate = !_vm.truncate
                    },
                  },
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  ref: "table3",
                  staticClass: "q-mt-sm",
                  attrs: {
                    title: "관련자료",
                    columns: _vm.grid3.columns,
                    gridHeight: _vm.grid3.height,
                    data: _vm.assessPlan.relatedDocuments,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    editable: false,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "table2",
        staticClass: "q-mt-sm",
        attrs: {
          title: "회의 목록",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.assessPlan.conferences,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }